import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ServiceSidebar from "@/components/service-sidebar";
import ServiceDetailsContent from "@/components/service-details-content";
import { ServiceDetailsData } from "@/data";

const ServiceDetails = (props) => {
  const { page } = props;
  const data = ServiceDetailsData[page]?ServiceDetailsData[page]:null;
  console.info(page, data);

  return (
    <section className="commonSection service_detail">
      <Container>
        <ServiceDetailsContent data={data} />
        {/*
        <Row>
          <Col lg={8} md={12}>
            <ServiceDetailsContent data={data} />
          </Col>
          <Col lg={4} md={12} className="sidebar">
            <ServiceSidebar />
          </Col>
        </Row>
          */}
      </Container>
    </section>
  );
};

export default ServiceDetails;
