import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import ServiceDetails from "@/components/service-details";
import CallToActionOne from "@/components/call-to-action-one";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import ServiceThree from "@/components/service-three";

import { ServiceDetailsData } from "@/data";

import { useQueryParam } from "gatsby-query-params";

const ServiceDetailsPage = () => {
  const page = useQueryParam("page");
  const data = ServiceDetailsData[page]?ServiceDetailsData[page]:null;

  console.info(page, data);

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        {data &&
        <Layout PageTitle="Service">
          <HeaderOne/>
          <PageBanner
            title={data.title}
            name="Service"
            //                      page={`service${page}`}
            page='services' />

          <ServiceDetails page={page}/>
          <ServiceThree />
          {/*
          <CallToActionOne extraClassName="ready"/>
          */}
          <Footer/>
        </Layout>
        }
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default ServiceDetailsPage;
